<template>
  <div class="aboutOurCompany">
    <div class="title"><h3>نتائج البحث</h3></div>
    <v-flex>
      <v-row>
        <v-col cols="12" lg="8" md="8" sm="12" xs="12" class="mt-10">
          <v-card max-width="650" class="mx-auto">
            <v-toolbar color="indigo" dark>
              <v-toolbar-title> نتائج البحث الحاليه (2022)</v-toolbar-title>
            </v-toolbar>

            <!--      <v-list-item :key="index" :to="item.link">
                      <v-list-item-avatar   size="60">
                           :src="item.mainImg" -->
            <!--       <v-img
                         :src="searchImg"
                     
                        ></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content class="mr-3">
                        <v-list-item-title>
                         {{ item.item }} <span></span></v-list-item-title>
                        <v-list-item-subtitle>{{item.created_at}} -<span> {{item.type}} </span></v-list-item-subtitle>
                        <v-list-item-subtitle class="black--text">
                          {{item.details}}
                        </v-list-item-subtitle>
                        
                      </v-list-item-content>
                    </v-list-item> -->
            <v-list>
              <template v-for="(item, index) in defaultArray">
                <v-list-item
                  v-show="index == 'news'"
                  v-for="(itemSelected, indexSelected) in item"
                  :key="indexSelected"
                  :to="{ name: 'News', params: { userId: itemSelected.slug } }"
                >
             
                  <v-list-item-avatar size="60">
                    <!-- :src="item.mainImg" -->
                    <v-img :src="searchImg"></v-img>
                  </v-list-item-avatar>
           
                  <v-list-item-content class="mr-3" v-if="index == 'news'">
                    <v-list-item-title>
                      {{ itemSelected.title }} <span></span
                    ></v-list-item-title>
                    <v-list-item-subtitle v-if="itemSelected.created_at"
                      >{{ itemSelected.created_at.substring(0, 16) }} -<span>
                        أخبار
                      </span></v-list-item-subtitle
                    >
                    <!-- <v-list-item-subtitle class="black--text">
                      {{ itemSelected.description }}
                    </v-list-item-subtitle> -->
                  </v-list-item-content>
                </v-list-item>
 <!-- projects table -->
                   <v-list-item
                  v-show="index == 'projects'"
                  v-for="(itemSelected, indexSelected) in item"
                  :key="indexSelected"
                  :to="{ name: 'ShowMore', params: { userId: itemSelected.slug } }"
                >
                  <v-list-item-avatar size="60">
                    <!-- :src="item.mainImg" -->
                    <v-img :src="searchImg"></v-img>
                  </v-list-item-avatar>
                 
                  <v-list-item-content class="mr-3" v-if="index == 'projects'">
                    <v-list-item-title>
                      {{ itemSelected.title }} <span></span
                    ></v-list-item-title>
                     <v-list-item-subtitle v-if="itemSelected.created_at"
                      >{{ itemSelected.created_at.substring(0, 16) }} -<span>
                       مشروعات
                      </span></v-list-item-subtitle 
                    >
                    <!-- <v-list-item-subtitle class="black--text">
                      {{ itemSelected.description }}
                    </v-list-item-subtitle> -->
                  </v-list-item-content>
                </v-list-item>

                <!-- tenders -->

                  <v-list-item
                  v-show="index == 'tenders'"
                  v-for="(itemSelected, indexSelected) in item"
                  :key="indexSelected"
                  :to="itemSelected.link"
                >
                  <v-list-item-avatar size="60">
                    <!-- :src="item.mainImg" -->
                    <v-img :src="searchImg"></v-img>
                  </v-list-item-avatar>
                  <!-- news table -->
                  <v-list-item-content class="mr-3" v-if="index == 'tenders'">
                    <v-list-item-title>
                      {{ itemSelected.item }} <span></span
                    ></v-list-item-title>
                    <v-list-item-subtitle v-if="itemSelected.created_at"
                      >{{ itemSelected.created_at.substring(0, 16) }} -<span>
                       مناقصات
                      </span></v-list-item-subtitle
                    >
                    <!-- <v-list-item-subtitle class="black--text">
                      {{ itemSelected.details }}
                    </v-list-item-subtitle> -->
                  </v-list-item-content>
                </v-list-item>
                <!-- navbar items -->
                  <v-list-item
                  v-show="index == 'navbar_items'"
                  v-for="(itemSelected, indexSelected) in item"
                  :key="indexSelected"
                  :to="itemSelected.link"
                >
                  <v-list-item-avatar size="60">
                    <!-- :src="item.mainImg" -->
                    <v-img :src="searchImg"></v-img>
                  </v-list-item-avatar>
                  <!-- news table -->
                  <v-list-item-content class="mr-3" v-if="index == 'navbar_items'">
                    <v-list-item-title>
                      {{ itemSelected.item }} <span></span
                    ></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3" md="3" sm="12" xs="12" class="ma-10">
          <Link></Link>
        </v-col>
      </v-row>
    </v-flex>
  </div>
</template>
<script>
import Link from "../components/link.vue";
export default {
  components: { Link },

  data() {
    return {
      searchImg: require("@/assets/Images/159.jpg"),
    };
  },
  computed: {
    defaultArray() {
      console.log(this.$store.state.newArraySearches)
      return this.$store.state.newArraySearches;
    },
  },
  methods: {
    subStringFun(st) {
      return st.substring(0, 10);
    },
  },
};
</script>
<style>
.imgClosed {
  margin-right: 200px;
}
.linkTender {
  text-decoration: none;
}
.backgroundClass {
  background: rgba(128, 128, 128, 0.199);
}
</style>